import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Logo from "../components/logo"

import styles from './index.module.css'

const IndexPage = () => (
  <Layout home={true}>
    <SEO title="Home" />
    <div className={styles.container}>
      <p className={styles.meet}>Meet</p>
      <Logo size={1}/>
      <h2 className={styles.punchLine}>The pill reminder for ADD & ADHD medicine that makes short release medication work as intended.</h2>
      <div className={styles.screens}>
        <div className={styles.innerScreens}>
          <Image className={styles.appScreenImageSide} name={'screen2'}/>
          <Image className={styles.appScreenImage} name={'screen1'}/>
          <Image className={styles.appScreenImageSide} name={'screen3'}/>
        </div>
      </div>
      <a className={styles.appIconLink} href="https://itunes.apple.com/app/id1436300542">
        <Image className={styles.appIconImage} name={'appIcon'}/>
      </a>
      <h4 className={styles.getForIphone}>Pill Punctual for iPhone</h4>
      <a href="https://itunes.apple.com/app/id1436300542" className={styles.appStoreLink}></a>
    </div>
  </Layout>
)

export default IndexPage
